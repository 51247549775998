
//Light mode
$middleGrey:#c7c7c7;
$lightGrey:#efefef;
$animDuration:0.2s;
$appBackgroundColorLight:#ffffff;

//Dark mode
$middleGreyDark:#c7c7c7;
$lightGreyDark:#4b4b4b;
$appBackgroundColorDark:#212121;

@keyframes appearAnimation{
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes savedLabelAnim{
  0%{opacity: 0;}
  25%{opacity: 1;}
  75%{opacity: 1;}
  100%{opacity: 0;}
}

@keyframes spinnerAnim{
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}


body, .App{
  margin:0;
  padding:0;

  font-family: "Mulish", Arial, Helvetica, sans-serif;

  input{
    outline: none;
  }
}


.App{
  width:100%;
  height:100vh;
  display: flex;
  justify-content: center;
  
  &.ligth{
    background-color: $appBackgroundColorLight;
  }

  &.dark{
    background-color: $appBackgroundColorDark;
  }

}

.loginPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  padding-top: 100px;


  >*{
    opacity: 0;
  }


  .loginPageLogo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin:0;
    padding:0;
    animation: appearAnimation $animDuration linear ($animDuration*1) forwards;

    &.light{
      * {color:black}
    }
    &.dark{
      *{color:white;}
      img{
        -webkit-filter: invert(100%); 
        filter: invert(100%);
      }
    }

    h1{
      font-weight: 500;
      font-size: 30px;
      margin:0;
      margin-right: 8px;
    }

    img{
      height:30px;
    }

  }

  h2{
    font-weight:300;
    font-size: 24px;
    margin:30px 0 60px 0;
    animation: appearAnimation $animDuration linear ($animDuration*2) forwards;
    &.light{color:black}
    &.dark{color:white;}
  }

  .loginContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:25%;
    box-shadow:0 0 10px darkgray;
    border-radius: 5px;
    animation: appearAnimation $animDuration linear ($animDuration*3) forwards;

    &.light{
      background-color: $lightGrey;
      box-shadow:0 0 10px darkgray;
      label{color:black;}
      button:last-of-type{color:rgb(114, 114, 114);}
    }
    &.dark{
      background-color: $lightGreyDark;
      box-shadow:none;
      border:1px solid rgb(105, 105, 105);
      label{color:white;}
      button:last-of-type{color:rgb(184, 184, 184);}
    }


    label{
      margin:30px 0 8px 0;
      font-size: 19px;
      font-weight: 600;
    }

    input{
      height:35px;
      width:80%;
      border-radius: 5px;
      border:1px solid darkgray;
      background-color: white;
      font-size: 18px;
      text-align: center;
      
    }

    button:first-of-type{
      margin:50px 0 10px 0;
      border:1px solid darkgray;
      background-color: rgb(211, 211, 211);
      color:black;
      font-size: 18px;
      width:60%;
      padding:8px 0;
      border-radius: 5px;
      cursor: pointer;
      transition:all 0.1s linear;

      &:hover{
        background-color: rgb(187, 187, 187);
      }

    }

    button:last-of-type{
      margin:50px 0 30px 0;
      font-size: 16px;
      width:60%;
      padding:8px 0;
      border-radius: 5px;
      cursor: pointer;
      background-color:transparent;
      border:none;
      text-decoration: underline;
    }
    
  }

}

.main{
  height:100vh;
  width: 100%;
  align-items: center;
  margin: 0;
  padding:0;
  
  .mainMenu{
    position:fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0;
    align-items: center;
    margin: 0;
    background-color: transparent;
    height: 50px;
    border-bottom: 1px solid darkgray;
    width:100%;

    .loginPageLogo{

      height:100%;
      display: flex;
      flex-direction: row;
      margin:0;
      padding:0;
      justify-content: center;
      align-items: center;
      margin-left: 30px;

      &.light{
        color:black;
      }
      &.dark{
        h1{color:white}
        img{ -webkit-filter: invert(100%); filter: invert(100%);}
      }
    
      h1{
        font-weight: 500;
        font-size: 22px;
      }

      img{
        height:30px;
        margin-left:8px;
      }
    }

    .mainMenuControls{
      display: flex;
      flex-direction: row;
      height:100%;
      align-items: center;
      margin-right: 30px;

      &.dark{
        img:not(.logoutButton img){ -webkit-filter: invert(100%); filter: invert(100%);}
        button{
          color:white; 
          font-weight: 400;

        }
      }
      
      button, a{
        margin:0 10px;
        height:35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border:none;
        background-color: inherit;
        cursor: pointer;
        transition:all 0.1s;
        font-size: 20px;
        font-weight: 700;

        &:hover{
          opacity: 0.8;
        }
  
        img{
          height:30px;
        }

        &.logoutButton{
          background-color: darkred;
          color:white;
          border-radius: 5px;
          padding:0 10px;
          font-size: 18px;
          font-weight: 400;

          img{
            margin-left: 8px;
            height:25px;
          }
        }
  
      }

    }

  }

  .textMenu{
    height:100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    bottom:0;
    left:0;
    width:100%;
    padding:0;
    margin:0;
    align-items: center;
    

    .textMenuDateHandler{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-left: 30px;
      height:40px;

      &.light{
        .date, select{
          border:1px solid black
        }
      }
      &.dark{
        .date, select{
          border: 1px solid white;
          color:white;
          font-weight: 400;
          img{ -webkit-filter: invert(100%); filter: invert(100%);}
        }
      }

      .date{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width:200px;
        padding:0 10px;


        select{
          height:95%;
          width: 70%;
          margin:0;
          text-align: right;
          border:none;
          font-weight: 600;
          font-size: 20px;
          background-color: transparent;

        }

        img{
          height:25px;
        }
      }

      button{
        height:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        width:140px;
        justify-content: center;
        border-radius: 5px;
        border:1px solid black;
        background-color: $lightGrey;
        cursor: pointer;
        transition:all 0.1s linear;
        margin:0;
        margin-left: 10px;
        

        &:hover{
          background-color: $middleGrey;
        }

        img{
          height:30px;
          margin-left: 8px;
        }
      }
    }
  
    .textMenuSaveHandler{
      display: flex;
      flex-direction: row;
      margin-right: 30px;
      height:40px;

      .genericInfo{
        border:1px solid black;
        line-height: 100%;
        border-radius: 5px;
        padding:0 25px;
        margin-right: 10px;
        line-height: 34px;
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9vw;
        font-weight: 700;
      }

      button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding:0 15px;
        margin:0 5px;
        border:none;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.1s linear;
        
        &:hover{
          opacity: 0.8;
        }

        img{
          height:25px;
          margin-left: 7px;
          
        }

        &:first-of-type{
          color:white;
          background-color: rgb(86, 86, 86);
        }

        &:last-of-type{
          color:white;
          background-color: darkgreen;
        }
      }

      &.dark{
        .genericInfo{
          border-color: white;
          color:white;
        }
      }
    }

  }

  .editorContainer{
    height:100%;
    width:100%;

    &.dark{
      .savedLabel{
        background-color: white;
        color:black;
      }
    }
    &.light{
      .savedLabel{
        background-color: black;
        color:white;
      }
    }

    .savedLabel{
      right:20px;
      top:60px;
      z-index: 1;
      position: absolute;
      margin:0;
      padding:5px 10px;
      border-radius: 5px;
      opacity: 0;
    }

    .savedLabelWithAnim{
      animation:savedLabelAnim 2s linear 0s 1 forwards;
    }

    .editor{
      height:84%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding:50px 50px 0 50px;
    
      
      .editorOriginal, .editorEdit{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin:10px 0;
        width:100%;
        height:33%;

        &.light{
          textarea{
            border:1px solid black;
            color:black;
            &:disabled{
              opacity: 0.7;
              background-color:$lightGrey;
            }
          }
        }
        &.dark{
          textarea{
            border:1px solid rgb(153, 153, 153);
            color:white;
            &:disabled{
              opacity: 0.7;
              background-color:$lightGreyDark;
            }
          }
        }
  
        textarea{
          height:94%;
          outline:none;
          width:18%;
          font-size: 17px;
          font-family:'Times New Roman', Times, serif;
          resize: none;
          background-color: transparent;
        }

      }

      .editorEdit:last-of-type{
        height:10%;
      }
  
      .textsInformations{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:35px 0 0 0;
        width:100%;

        &.light{
          .textsInformationsItem{color:black}
        }
        &.dark{
          .textsInformationsItem{color:white}
        }

        .textsInformationsItem{
          margin:0;
          text-align: center;
          font-size: 0.7vw;
          width:18%;

          .bold{
            font-weight: 900;
            font-size: 0.9vw;
          }
        }
      }

    
    }
  }

  .loadingPage{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.light{
      p{color:black}
      .spinner{
        border-color: black;
        border-top-color:white;
      }
    }

    &.dark{
      p{color:white}
      .spinner{
        border-color: white;
        border-top-color:$appBackgroundColorDark;
      }
    }

    p{
      font-size: 25px;
    }

    .spinner{
      width:150px;
      height:150px;
      border:10px solid black;
      border-top:10px solid white;
      margin-top: 40px;
      border-radius: 50%;
      animation:spinnerAnim 1s linear infinite;
    }

  }

}

.modal{
  position: absolute;
  height:100%;
  width:100%;
  background-color: rgba(black, 0.8);
  z-index: 1;
  opacity: 0;
  animation: appearAnimation 0.1s linear forwards;

  .modalContainer{
    height:80%;
    width:80%;
    position: absolute;
    margin:5% 10%;
    overflow-y: auto;

    &.dark{
      background-color: $appBackgroundColorDark;
    }
    &.light{
      background-color: white;
    }
    

    .closeModal{
      background-color: darkred;
      cursor: pointer;
      height:40px;
      width:40px;
      border-radius: 50%;
      border:none;
      color:white;
      font-size: 22px;
      transition:all 0.2s linear;
      margin:10px 10px 0 0;
      position: absolute;
      right:0;
      padding:0;
      display: flex;
      justify-content: center;
      line-height: 35px;

      &:hover{
        opacity: 0.8;
      }
    }

    h1{
      text-align: center;
      font-weight: normal;
      font-size: 25px;
      margin:50px 0;
    }

  }
}

.firstConnection{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50%;
    margin:0 25%;

    &.light{
      *{
        color:black;
      }
      .valider{
        background-color: $middleGrey;
        color:black;
      }
    }
    &.dark{
      *{
        color:white;
      }
      .valider{
        background-color: white;
        color:black;
      }
    }


    label{
      margin:30px 0 8px 0;
      font-size: 19px;
      font-weight: 600;
    }

    input{
      height:35px;
      width:60%;
      border-radius: 5px;
      border:1px solid darkgray;
      background-color: transparent;
      font-size: 18px;
      text-align: center;
      margin:10px;
    }

    .valider{
      margin:50px 0 10px 0;
      font-size: 18px;
      width:40%;
      padding:10px 0;
      border-radius: 5px;
      cursor: pointer;
      transition:all 0.1s linear;
      border: none;

      &:hover{
        opacity: 0.8;
      }

    }

    .goBack{
      margin:50px 0 30px 0;
      padding:10px;
      font-size: 16px;
      width:40%;
      padding:8px 0;
      border-radius: 5px;
      cursor: pointer;
      border:none;
      background-color: transparent;
      text-decoration: underline;
    }
}

.settings{
  margin:0;
  padding:0 10%;

  &.light{
    h1{color:black}
  }
  &.dark{
    h1{color:white;}
  }

  h1{
    text-align: center;
    font-weight: normal;
    font-size: 25px;
    margin:40px 0 20px 0;
  }

  .userData{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;

    .userDataItem{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-color: gainsboro;
      padding:0;
      width:20%;
      border-radius: 5px;

      img{
        width:20%;
        margin:30px 0 10px 0;
      }

      h3{
        font-size: 18px;
        font-weight: bold;
        margin: 10px 0;
        text-align: center;
      }

      p{
        margin:10px 0 30px 0;
      }
    }

    &:last-of-type{
      .userDataItem{
        cursor: pointer;
        transition:all 0.2s linear;

        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
  
}

.notes{
  display: flex;
  flex-direction: column;
  align-items: center;

  &.light{
    textarea, h1{
      color:black
    }
  }
  &.dark{
    textarea, h1{
      color:white;
    }
  }

  textarea{
    height:400px;
    width: 90%;
    outline: none;
    font-size: 19px;
    font-weight: 400;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    background-color: transparent;
  }


  button{
    width:20%;
    height:40px;
    font-size: 18px;
    border-radius: 5px;
    border:1px solid $middleGrey;
    cursor:pointer;
    margin:50px 0;
  }
}

.approvals{

  &.dark{
    *{color:white;}
    .approvalItem{
      background-color: $lightGreyDark;
    }
  }
  &.light{
    *{color:black;}
    .approvalItem{
      background-color: $lightGrey;
    }
  }

  .approvalItem{
    background-color: $lightGreyDark;
    width:84%;
    margin:10px 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height:70px;
    align-items: center;
    padding:0 20px;
    border-radius: 5px;
  
    p{
      flex:2.5;
      text-align: left;
    }

    button{
      flex:1;
      height:70%;
      color:white;
      background-color: darkgreen;
      border:none;
      cursor:pointer;
      border-radius: 5px;
      transition: all 0.2s linear
    }

    button:hover{
      opacity: 0.9;
    }
  
  }
}